import React from "react";
import BaseLayout from "../components/BaseLayout";
import { Widget } from "@typeform/embed-react";

import { StyledPre } from "../styles/StyledPre";

function Pre({ pageContext: { fechedOfferList } }) {
  const isBrowser = () => typeof window !== "undefined";
  isBrowser() && window.location.assign("https://www.pfresolu.com/Carrieres/");
  return (
    <BaseLayout destailPage={true} footerMap={false} footerAdress={false}>
      <StyledPre>
        <Widget id="vqFicjtR" style={{ width: "100%" }} className="stasForm" />
      </StyledPre>
    </BaseLayout>
  );
}

export default Pre;
